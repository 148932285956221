import { frontendURL } from '../../../../helper/URLHelper';
const ChecklistsView = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/checklists/answer'),
    roles: ['administrator'],
    component: ChecklistsView,
    props: {
      headerTitle: 'SIDEBAR.ALL_CHECKLISTS',
      icon: 'checklist-list',
      showBackButton: true,
    },
    children: [
      {
        path: '',
        name: 'checklists_answer_index',
        component: Index,
        roles: ['administrator'],
        props: route => ({
          overview: route.params.overview,
        }),
      },
    ],
  },
];
