/* global axios */
import ApiClient from './ApiClient';

class ChecklistAPI extends ApiClient {
  constructor() {
    super('checklists', { accountScoped: true });
  }

  get() {
    return axios.get(this.url);
  }

  getUserAnswer(requestParams) {
    return axios.get(`${this.url}/${requestParams.checklist_id}/user_answer`, {
      params: requestParams.params,
    });
  }

  create(form) {
    return axios.post(this.url, form);
  }

  getOverview(filters) {
    return axios.get(`${this.url}/overview`, {
      params: filters,
    });
  }
}

export default new ChecklistAPI();
