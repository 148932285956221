<template>
  <div>
    <button 
      @click="openChat" 
      class="text-slate-600 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
    >
      <support-icon/>
    </button>
  </div>
</template>
<script>
import SupportIcon from '../../../assets/icons/SupportIcon.vue'

export default {
  components: {
    SupportIcon
  },
  mounted() {
    window.$vordesk.baseUrl = 'https://app2.vordesk.jera.app.br'
    window.$vordesk.websiteToken = 'bPuLnxkKRRt6dDYphR7ke2kN'
  },
  methods: {
    openChat() {
      window.$vordesk.popoutChatWindow()
    }
  }
};
</script>
