import { frontendURL } from '../../../helper/URLHelper';

const ChecklistsContent = () => import('./Wrapper.vue');

import { routes as listRoutes } from './list/list.routes';
import { routes as appliedRoutes } from './applied/applied.routes';
import { routes as createRoutes } from './create/create.routes';
import { routes as answerChecklistsRoutes } from './answer/answer.routes';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/checklists'),
    name: 'checklists_home',
    component: ChecklistsContent,
    roles: ['administrator'],
    redirect: () => frontendURL('accounts/:accountId/checklists/dashboard'),
  },
  ...listRoutes,
  ...appliedRoutes,
  ...createRoutes,
  ...answerChecklistsRoutes,
];
