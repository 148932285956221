<!-- eslint-disable vue/no-mutating-props -->
<template>
  <modal :show.sync="show" :on-close="closeModal" :show-close-button="false">
    <woot-modal-header :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.VERIFICATION_CODE.TITLE')" :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.VERIFICATION_CODE.CONTENT')" />

    <LoadingState v-if="isCreating" />

    <div v-else class="flex flex-row gap-x-4 px-8 py-6">
      <input
        type="number"
        class="w-12 h-16 flex justify-center items-center"
        v-model="firstDigit"
        @input="onDigitChanged(1, 0)"
      />
      <input
        type="number"
        class="w-12 h-16 flex justify-center items-center"
        maxlength="1"
        v-model="secondDigit"
        @input="onDigitChanged(2, 1)"
      />
      <input
        type="number"
        class="w-12 h-16 flex justify-center items-center"
        maxlength="1"
        v-model="thirdDigit"
        @input="onDigitChanged(3, 2)"
      />
      <input
        type="number"
        class="w-12 h-16 flex justify-center items-center"
        maxlength="1"
        v-model="fourthDigit"
        @input="onDigitChanged(4, 3)"
      />
      <input
        type="number"
        class="w-12 h-16 flex justify-center items-center"
        maxlength="1"
        v-model="fiftyDigit"
        @input="onDigitChanged(5, 4)"
      />
      <input
        type="number"
        class="w-12 h-16 flex justify-center items-center"
        maxlength="1"
        v-model="sixtyDigit"
        @input="onDigitChanged(0, 5)"
      />
    </div>
    <button
      @click="goToNextStep"
      class="float-right mb-4 mx-8 py-4 px-8 rounded-xl cursor-pointer"
      :class="isFilled ? 'bg-green-500 hover:bg-purple-500' : 'bg-gray-900'"
    >
      <p>Avançar</p>
    </button>
  </modal>
</template>

<script>
import Modal from '../../../../../components/Modal.vue';
import LoadingState from '../../../../../components/widgets/LoadingState.vue';

export default {
  components: {
    Modal,
    LoadingState,
  },
  data() {
    return {
      firstDigit: '',
      secondDigit: '',
      thirdDigit: '',
      fourthDigit: '',
      fiftyDigit: '',
      sixtyDigit: '',
      isFilled: false,
    }
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    isCreating: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onConfirm() {
      this.$emit('on-confirm');
    },
    onDigitChanged(newInputIndex, oldInputIndex) {
      const inputs = document.getElementsByTagName('input');
      const inputValue = inputs[oldInputIndex].value.slice(-1);

      switch(oldInputIndex) {
        case 0:
          this.firstDigit = inputValue;
          break;
        case 1:
          this.secondDigit = inputValue;
          break;
        case 2:
          this.thirdDigit = inputValue;
          break;
        case 3:
          this.fourthDigit = inputValue;
          break;
        case 4:
          this.fiftyDigit = inputValue;
          break;
        case 5:
          this.sixtyDigit = inputValue;
          break;
      }

      const isValuesFilled = this.firstDigit != '' && this.secondDigit != '' && this.thirdDigit != '' && this.fourthDigit != '' && this.fiftyDigit != '' && this.sixtyDigit != '';

      if(isValuesFilled) {
        this.isFilled = true;
        return;
      }

      this.isFilled = false;

      if(inputValue == '') return;

      inputs[newInputIndex].focus();
      inputs[oldInputIndex].blur();
    },
    goToNextStep() {
      const code = this.firstDigit + this.secondDigit + this.thirdDigit + this.fourthDigit + this.fiftyDigit + this.sixtyDigit;
      this.$emit('go-to-next-step', code);
    },
    closeModal() {
      // TODO: Verificar se a necessidade de ter a opção de fechar o modal
    }
  },
};
</script>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

p {
  margin: 0px;
  padding: 0px;
  color: white;
}
</style>
