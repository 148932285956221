import { frontendURL } from '../../../../helper/URLHelper';

const checklists = accountId => ({
  parentNav: 'checklists',
  routes: [
    'checklists_index',
    'checklists_create',
    'checklists_applied',
    'checklists_answer_index',
  ],
  menuItems: [
    {
      icon: 'checklist-list',
      label: 'ALL_CHECKLISTS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/checklists/dashboard`),
      toStateName: 'checklists_index',
      stateChild: ['checklists_create'],
    },
    {
      icon: 'checklist',
      label: 'APPLIED_CHECKLISTS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/checklists/applied`),
      toStateName: 'checklists_applied',
      stateChild: ['checklists_answer_index'],
    },
  ],
});

export default checklists;
