import { getters } from './getters';
import { actions, defaultChecklist } from './actions';
import { mutations } from './mutations';

const state = {
  checklist: defaultChecklist(),
  answerTypes: [],
  uiFlags: {
    isFetching: false,
    hasError: false,
    isCreatingChecklist: false,
    hasErrorToCreateChecklist: false,
    successToCreateChecklist: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
