import ChecklistAPI from '../../../api/checklist';
import types from '../../mutation-types';

export const actions = {
  get: async ({ commit }) => {
    try {
      commit(types.SET_CHECKLIST_UI_FLAG, { isFetching: true });
      const { data } = await ChecklistAPI.get();
      commit(types.SET_CHECKLISTS, data);
      commit(types.SET_CHECKLIST_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CHECKLIST_UI_FLAG, {
        isFetching: false,
        hasError: true,
      });
    }
  },
  getUserAnswer: async ({ commit }, params = null) => {
    try {
      commit(types.SET_USER_ANSWER_UI_FLAG, { isFetching: true });
      const { data } = await ChecklistAPI.getUserAnswer(params);
      commit(types.SET_USER_ANSWER, data);
      commit(types.SET_USER_ANSWER_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_USER_ANSWER_UI_FLAG, {
        isFetching: false,
        hasError: true,
      });
    }
  },
  getOverview: async ({ commit }, filters = null) => {
    try {
      commit(types.SET_CHECKLIST_UI_FLAG, { isFetching: true });
      const { data } = await ChecklistAPI.getOverview(filters);
      commit(types.SET_CHECKLIST_OVERVIEW, data);
      commit(types.SET_CHECKLIST_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CHECKLIST_UI_FLAG, {
        isFetching: false,
        hasError: true,
      });
    }
  },
};
