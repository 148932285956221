import { frontendURL } from '../../../../helper/URLHelper';
const ChecklistsView = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/checklists/applied'),
    roles: ['administrator'],
    component: ChecklistsView,
    props: {
      headerTitle: 'SIDEBAR.APPLIED_CHECKLISTS',
      icon: 'checklist',
    },
    children: [
      {
        path: '',
        name: 'checklists_applied',
        component: Index,
        roles: ['administrator'],
      },
    ],
  },
];
