export const daysOfWeekEnum = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
};

export const convertToListOfIndex = daysOfWeek => {
  const daysOfWeekValues = Object.values(daysOfWeekEnum);

  return daysOfWeek.map(dayOfWeek => {
    return daysOfWeekValues.indexOf(dayOfWeek);
  });
};
