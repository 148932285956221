import types from '../../mutation-types';

export const mutations = {
  [types.SET_CHECKLISTS]($state, data) {
    $state.checklists = data;
  },
  [types.SET_USER_ANSWER]($state, data) {
    $state.userAnswer = data;
  },
  [types.SET_CHECKLIST_OVERVIEW]($state, data) {
    $state.overview = data;
  },
  [types.SET_CHECKLIST_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_USER_ANSWER_UI_FLAG]($state, data) {
    $state.uiFlagsUserAnswer = {
      ...$state.uiFlagsUserAnswer,
      ...data,
    };
  },
};
