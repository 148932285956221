import types from '../../../mutation-types';

export const mutations = {
  [types.SET_ANSWER_TYPES]($state, data) {
    $state.answerTypes = data;
  },
  [types.SET_CREATE_CHECKLIST_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_CREATE_QUESTION]($state, data) {
    const questionsSize =
      $state.checklist.sections_attributes[data].questions_attributes.length;
    let questionId = 0;

    if (questionsSize >= 1) {
      questionId =
        $state.checklist.sections_attributes[data].questions_attributes[
          questionsSize - 1
        ].question_id + 1;
    }

    $state.checklist.sections_attributes[data].questions_attributes.push({
      question_id: questionId,
      question: 'Título da pergunta',
      checklist_answer_subtype_id: null,
      has_comment: false,
      has_attachment: false,
      required: false,
    });
  },
  [types.SET_DELETE_QUESTION]($state, data) {
    const sectionIndex = data.sectionIndex;
    const questionIndex = data.questionIndex;
    $state.checklist.sections_attributes[
      sectionIndex
    ].questions_attributes.splice(questionIndex, 1);
  },
  [types.SET_CREATE_SECTION]($state) {
    const sectionsSize = $state.checklist.sections_attributes.length;
    let sectionId = 0;

    if (sectionsSize >= 1) {
      sectionId =
        $state.checklist.sections_attributes[sectionsSize - 1].section_id + 1;
    }

    $state.checklist.sections_attributes.push({
      section_id: sectionId,
      title: 'Área sem título',
      description: 'Descrição da área',
      questions_attributes: [],
    });
  },
  [types.SET_DELETE_SECTION]($state, data) {
    $state.checklist.sections_attributes.splice(data, 1);
  },
  [types.SET_CREATE_CHECKLIST]($state, data) {
    $state.checklist = data;
  },
  [types.SET_FREQUENCY]($state, data) {
    $state.checklist.frequency = data;
  },
  [types.SET_DAYS_OF_WEEK]($state, data) {
    $state.checklist.days_of_week = data;
  },
};
