/* global axios */
import ApiClient from './ApiClient';

class ChecklistAPI extends ApiClient {
  constructor() {
    super('checklist_answer_types', { accountScoped: true });
  }

  get() {
    return axios.get(this.url);
  }
}

export default new ChecklistAPI();
