import { frontendURL } from '../../../../helper/URLHelper';
const ChecklistsView = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/checklists/dashboard'),
    roles: ['administrator'],
    component: ChecklistsView,
    props: {
      headerTitle: 'SIDEBAR.ALL_CHECKLISTS',
      headerButtonText: 'CHECKLISTS.NEW_CHECKLIST',
      icon: 'checklist-list',
      newButtonRoutes: ['checklists_index'],
    },
    children: [
      {
        path: '',
        name: 'checklists_index',
        component: Index,
        roles: ['administrator'],
      },
    ],
  },
];
