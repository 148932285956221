export const getters = {
  getChecklist($state) {
    return $state.checklist;
  },
  getAnswerTypes($state) {
    return $state.answerTypes;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};
