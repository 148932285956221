import ChecklistAnswerTypesAPI from '../../../../api/checklist_answer_types';
import ChecklistAPI from '../../../../api/checklist';
import types from '../../../mutation-types';
import { repetitionTypesEnum } from '../../../../constants/checklist/repetition_types';
import { convertToListOfIndex } from '../../../../constants/checklist/days_of_week';

const defaultChecklist = () => ({
  title: 'Título do formulário',
  description: 'Descrição do formulário',
  frequency: repetitionTypesEnum.NOT_REPEAT,
  days_of_week: [],
  user_forms_attributes: [],
  sections_attributes: [
    {
      section_id: 0,
      title: 'Área sem título',
      description: 'Descrição da área',
      questions_attributes: [],
    },
  ],
});

export { defaultChecklist };

export const actions = {
  getAnswerTypes: async ({ commit }) => {
    try {
      commit(types.SET_CREATE_CHECKLIST_UI_FLAG, { isFetching: true });
      const { data } = await ChecklistAnswerTypesAPI.get();
      commit(types.SET_ANSWER_TYPES, data);
      commit(types.SET_CREATE_CHECKLIST_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CREATE_CHECKLIST_UI_FLAG, {
        isFetching: false,
        hasError: true,
      });
    }
  },

  resetChecklist: ({ commit }) => {
    commit(types.SET_CREATE_CHECKLIST, defaultChecklist());
  },

  post: async ({ commit }, checklist) => {
    try {
      commit(types.SET_CREATE_CHECKLIST_UI_FLAG, {
        isCreatingChecklist: true,
        hasErrorToCreateChecklist: false,
        successToCreateChecklist: false,
      });
      const localChecklist = { ...checklist };
      localChecklist.days_of_week = convertToListOfIndex(
        localChecklist.days_of_week
      );
      await ChecklistAPI.create(localChecklist);
      commit(types.SET_CREATE_CHECKLIST_UI_FLAG, {
        isCreatingChecklist: false,
        successToCreateChecklist: true,
      });
    } catch (error) {
      commit(types.SET_CREATE_CHECKLIST_UI_FLAG, {
        isCreatingChecklist: false,
        hasErrorToCreateChecklist: true,
      });
    }
  },
};
