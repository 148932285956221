export const getters = {
  getChecklists($state) {
    return $state.checklists;
  },
  getUserAnswer($state) {
    return $state.userAnswer;
  },
  getChecklistOverview($state) {
    return $state.overview;
  },
  getChecklistsInAlphabeticalOrder($state) {
    return $state.checklists.sort((a, b) => a.title.localeCompare(b.title));
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getUIFlagsUserAnswer($state) {
    return $state.uiFlagsUserAnswer;
  },
};
